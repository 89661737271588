<template>
  <div>
    <div class="page-top-nav">
      <a-tabs type="card" :defaultActiveKey="activeType">
        <a-tab-pane v-for="item in typesMap.type" :key="item.value" :tab="item.label">
          <CarRiskList v-if="item.value === 0"></CarRiskList>
          <DeviceRiskList v-if="item.value === 1"></DeviceRiskList>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import CarRiskList from './modules/CarRiskList.vue'
import DeviceRiskList from './modules/DeviceRiskList.vue'

export default {
  components: {
    DeviceRiskList,
    CarRiskList,
  },

  data() {
    return {
      activeType: 0,
    }
  },
  computed: {
    typesMap() {
      return {
        type: [
          { value: 0, label: '车辆风险' },
          { value: 1, label: '设备风险' },
        ],
      }
    },
  },
  methods: {},
  async mounted() {},
}
</script>

<style lang="less" scoped></style>
